import React, { useState, useContext } from 'react';
import { Link, useStaticQuery } from 'gatsby';
import { device } from '../../constants/mediaQueries';
import styled from 'styled-components';
import { orangeGradient, colors, navbarDropDownPointer } from '../../constants/styles';
import { ModalContext } from '../layout';

const TopList = styled.ul`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0;
    list-style: none;
    margin: 0;
    font-weight: 300;
    background: ${colors.white};

    > li {
        padding: 0;
        margin-bottom: 0;
        cursor: pointer;

        ul {
            visibility: hidden;
        }

        > a, .menu-dropdown {
            display: block;
            padding: 1.8rem 0.5rem;
        }
    }

    li.active {

        ul {
            visibility: visible;
    
        }

        .menu-dropdown {
            position: relative;

            ${navbarDropDownPointer}
        }
    }

    a {
        color: inherit;
    }

`;

const InnerList = styled.ul`
    display: block;
    position: absolute;
    top: 100%;
    background: rgba(41, 41, 94, 0.93);
    margin: 0;
    list-style:none;
    padding: 0.8rem 1.5rem;

    li {
        padding: 0.5rem 0;
    }

    a {
        color: ${colors.white};
    }

`;

const InnerListAdvanced = styled.ul`

        position: absolute;
        top: 100%;
        left: 0;
        background: ${colors.purpleTransparent};
        color: ${colors.white};
        width: 100%;
        margin: 0;

    .container {
        display: flex;   
    }
    

    li {
        flex-grow: 1;
        flex-basis: 0;
        list-style: none;
        padding: 0;
        padding-right: 1.6rem;
        display: flex;
        width: 25%;
        margin-bottom: 0;

        a {
            color: #fff;
            padding: 1rem 0.4rem 1rem 0.95rem;       
            position: relative;
            height: 10rem;
            max-height: 10rem;
            font-size: 0.8rem;
            line-height: 1.2rem;

            &.current {
                background: ${colors.darkBlue};

                &::after {
                    content: '';
                    width: 0;
                    height: 0;
                    border-style: solid;
                    border-width: 89px 0 89px 30px;
                    border-color: transparent transparent transparent ${colors.darkBlue};
                    position: absolute;
                    top: 0;
                    right: -29px;
                }
            }
        }   
    }

    .title {
        font-weight: bold;
        color: ${colors.orange};
        text-transform: uppercase;
        line-height: 1.2rem;
        margin: 0;
        margin-bottom: 0.2rem;
        font-size: 0.83rem;
        letter-spacing: 0;

        span {
            color: ${colors.white};
        }
    }
`;

const Nav = styled.nav`
    
    flex-grow: 1;
    justify-content: space-between;
    display: none;
    background: ${colors.white};

    @media ${device.deviceM}{
        display: flex;
    }
`;

const GetInTouch = styled.div`
    ${orangeGradient}
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 1rem;

    button {
        color: ${colors.white};
        display: flex;
        align-items: center;
        border: none;
        background: transparent;
        margin: 0;
        margin-right: 1rem;
        padding: 0;
        cursor: pointer;

        img {
            margin-right: -0.2rem;
        }

        &:focus {
            outline: none;
        }
    }

    .partner {
        width: 117px;
        height: 52px;
        

        img {
            margin-bottom: -1.6rem;
            max-width: 200%;
        }
    }
`;

const DesktopNavbar = ({ data }) => {
    const modalTools = useContext(ModalContext);

    const [ activeDropdown, setActiveDropdown ] = useState(null);
    const [ dropdownOpen, setDropdownOpen ] = useState(false);

    const url = typeof window !== 'undefined' ? window.location.href : '';

    const toggleDropdown = (categoryCLicked) => {
        if (dropdownOpen) {
            if (activeDropdown === categoryCLicked){
                setDropdownOpen(false);
                setActiveDropdown(null);
            } else {
                setActiveDropdown(categoryCLicked);
            }
        } else {
            setDropdownOpen(true);
            setActiveDropdown(categoryCLicked);
        }
    }

    const { print, partner } = useStaticQuery(graphql`
       query {
            print: allFile(filter: {name: {eq: "print"}, extension: {eq: "png"}}) {
                edges {
                    node {
                        publicURL
                    }
                }
            }
            partner: allFile(filter: {name: {eq: "partner"}, extension: {eq: "png"}}) {
                edges {
                    node {
                        publicURL
                    }
                }
            } 
        }
    `);
    const printURL = print.edges[0].node.publicURL;
    const partnerURL = partner.edges[0].node.publicURL;
    return (
        <Nav>
            <TopList>
                <li className={activeDropdown === 'steps' ? 'steps active' : 'steps'} onClick={() => {toggleDropdown('steps')}}>
                    <span className="menu-dropdown">Profit Formula</span>
                    <InnerListAdvanced>
                        <div className="container">
                            {data.allContentfulSteps.edges.map(({ node }) => {
                                return (
                                    <li key={node.title}>
                                        <Link to={`/steps/${node.link}/`} activeClassName="current">
                                            <p className="title">
                                                <span>{node.orderNumber} / </span>
                                                {node.title}
                                            </p>
                                            <p className="desc">
                                            {node.shortDescription} 
                                            </p>    
                                        </Link>
                                    </li>
                                );
                            })}
                        </div>
                    </InnerListAdvanced>
                </li>
                <li className={activeDropdown === 'services' ? 'services active' : 'services'} onClick={() => {toggleDropdown('services')}}>
                    <span className="menu-dropdown">Services</span>
                    <InnerListAdvanced>
                        <div className="container">
                            {data.allContentfulServices.edges.map(({ node }, ind) => {
                                return (
                                    <li key={node.title}>
                                        <Link to={`/services/${node.link}/`} activeClassName="current">
                                            <p className="title">
                                                <span>Stage {node.orderNum}. </span>
                                                {node.title}
                                            </p>
                                            <p className="desc">
                                            {node.shortDescription} 
                                            </p>
                                        </Link>
                                    </li>
                                
                                );
                            })}
                        </div>
                    </InnerListAdvanced>
                </li>
                <li onClick={() => {toggleDropdown(null)}}>
                    <Link to="/pricing/">Pricing</Link>
                </li>
                <li onClick={() => {toggleDropdown(null)}}>
                    <Link to="/cases/">Cases</Link>
                </li>
                <li className={activeDropdown === 'agency' ? 'agency active' : 'agency'} onClick={() => {toggleDropdown('agency')}}>
                    <span className="menu-dropdown">Agency</span>
                    <InnerList>
                        <li>
                            <Link to="/about-us/">About Optimozg</Link>
                        </li>
                        <li>
                            <Link to="/team-optimozg/">Team Optimozg</Link>
                        </li>
                        <li>
                            <Link to="/contact-us/">Contact Us</Link>
                        </li>
                    </InnerList>
                </li>
            </TopList>
            <GetInTouch >
                <button onClick={() => modalTools.handleModalCall(true, 'DesktopNavbar')}>
                    <img src={printURL} alt="Get In Touch" />
                    Get In Touch
                </button>
                <div className="partner">
                    <img src={partnerURL} alt="Google Partner" />
                </div>
            </GetInTouch>
        </Nav>
    );
}

export default DesktopNavbar;