import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';

const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    img {
        margin: 0.3rem;
    }
`;

const PartnerLogos = () => {
    const images = useStaticQuery(graphql`
        query {
            bing: allFile(filter: {name: {eq: "bing"}, extension: {eq: "jpg"}}) {
                edges {
                    node {
                    publicURL
                    }
                }
            }
            googleAnalytics: allFile(filter: {name: {eq: "googleAnalytics"}, extension: {eq: "png"}}) {
                edges {
                    node {
                    publicURL
                    }
                }
            }
            googlePartner: allFile(filter: {name: {eq: "googlePartner"}, extension: {eq: "jpg"}}) {
                edges {
                    node {
                    publicURL
                    }
                }
            }
        }
    `);
    return (
        <Container className="container">
            <img src={images.bing.edges[0].node.publicURL} alt="Bing" />
            <img src={images.googleAnalytics.edges[0].node.publicURL} alt="Google Analytics Technology Partner" />
            <img src={images.googlePartner.edges[0].node.publicURL} alt="Premier Google Partner" />
        </Container>
    );

}

export default PartnerLogos;