import { Link, useStaticQuery, graphql } from "gatsby";
import PropTypes from "prop-types";
import React from "react";
import Navigation from '../components/navigation';
import styled from 'styled-components';
import { device } from '../constants/mediaQueries';
import { headerGradientBackground } from '../constants/styles';

const Logo = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  padding: 0.5rem 1rem;
  flex-shrink: 0;

  @media ${device.deviceM} { 
    justify-content: flex-start;
    width: auto;
    padding: 1.2rem 1rem;
  }

  img {
    margin-bottom: 0;
    display: block;
  }
`;

const HeaderWrap = styled.header`
  @media ${device.deviceM} { 
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
  }
`;

const HeaderBackground = styled.div`
  
  position: relative;
  
    >.container {
      margin: 0;
      padding: 0;
      max-width: 100%;
    }

  @media ${device.deviceM} {
    ${headerGradientBackground}

    >.container {
      padding-right: 15px;
      padding-left: 15px;
      margin-right: auto;
      margin-left: auto;
      max-width: 960px;
    }
  }
  @media ${device.deviceL} {
    >.container {
      max-width: 1140px;
    }
  }
`;

const Header = ({ siteTitle }) => {
  const { allFile: {
    edges: [
      { node: {
          publicURL
      }}
    ]
  } } = useStaticQuery(
    graphql`
      query {
          allFile(filter: {name: {eq: "logo"}, extension: {eq: "jpg"}}) {
              edges {
                node {
                  publicURL
                }
              }
          } 
      }
    `
  );
  return (
    <HeaderBackground>
      <div className="container">
          <HeaderWrap>
            <Navigation />
            <Logo>
              <Link to="/">
                  <img src={publicURL} alt="Optimozg" />
              </Link>
            </Logo>
          </HeaderWrap>
      </div>
    </HeaderBackground>
  );
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: `Optimozg`,
}

export default Header
