import React, { useContext } from 'react';
import { zoomIn } from 'react-animations';
import styled, { keyframes } from 'styled-components';
import { blueGradient, colors } from '../../constants/styles';
import { ModalContext } from '../layout';
import PersonalStrategyForm from '../forms/PersonalStrategyForm';

const zoomInAnimation = keyframes`${zoomIn}`;

const ModalWrap = styled.div`
    display: none;
    overflow: hidden;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1050;
    -webkit-overflow-scrolling: touch;
    outline: 0;
    animation: 0.35s ${zoomInAnimation};

    ${({ show }) => show && `
        overflow-x: hidden;
        overflow-y: auto;
        display: flex;
    `}
    
    .modal-dialog {
        display: flex;
        flex-grow: 1;
    }

    .modal-content {
        ${blueGradient}
        padding: 2rem;
        opacity: .9;
        color: ${colors.white};
        display: flex;
        width: 100%;
        height: 100%;
        align-items: center;
        justify-content: center;

        h1 {
            font-weight: 300;
            font-size: 30px;
            color: ${colors.white};
            text-transform: uppercase;
            letter-spacing: .06em;
        }
    }

    .close {
        position: absolute;
        top: 1rem;
        right: 0.5rem;
        font-weight: bold;
        text-shadow: 0 1px 0 ${colors.white};
        padding: 0;
        cursor: pointer;
        background: transparent;
        border: 0;
        -webkit-appearance: none;
        color: ${colors.white};
        opacity: .5;
        font-size: 2.5rem;
        margin-right: 0.5rem;
    }

    .modal-body {
        max-width: 700px;
        width: 90%;
        text-align: center;
    }
`;

const Modal = ({ clickSource }) => {

    const { handleModalCall, displayModal } = useContext(ModalContext);
    return (
        <ModalWrap tabindex="-1" show={displayModal}>
            <div className="modal-dialog">
                <div className="modal-content">
                    <button type="button" className="close" onClick={() => handleModalCall(false, 'unknown source')}>×</button>
                    <div className="modal-body form-wrap">
                        <h1>Let us create a pesonalysed strategy for your business</h1>
                        <p>We are offering to follow along this way with us. We have all the necessary skills and tools to lead your business straight to profit growth!</p>
                        <PersonalStrategyForm clickSource={clickSource} />
                    </div>
                </div>
            </div>
        </ModalWrap>
    );
}

export default Modal;