export const orangeGradient = `
    background: #ff6448;
    background: linear-gradient(to right, #ff6448 0%, #feab4c 100%);
    filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#ff6448', endColorstr='#feab4c', GradientType=1);
`;

export const buttonBackground = `
    background: #ff9449;
    background: linear-gradient(to right, #ff9449 0%, #ff594a 100%);
    filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#ff9449', endColorstr='#ff594a', GradientType=1);
`;

export const greyGradient = `
    background-image: linear-gradient(180deg, #ffffff 0%, #f3f3f3 100%);
`;

export const whiteGradient = `
    background: linear-gradient(to bottom,white 0%,white 70%,rgba(255,255,255,0) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff',endColorstr='#00ffffff',GradientType=0);
`;

export const headerGradientBackground = `
    background: white;
    background: linear-gradient(to right, white 0%, white 50%, #feab4c 50%, #feab4c 100%);
    filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#feab4c', GradientType=1);
`;

export const navbarDropDownPointer = `
    &:after {
        content: '>';
        position: absolute;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 12.5px 12px 12.5px;
        border-color: transparent transparent #2d2e64 transparent;
        bottom: 0;
        left: 50%;
        transform: translate(-50%, 0px);
        -webkit-transform: translate(-50%, 0px);
        -moz-transform: translate(-50%, 0px);
        -o-transform: translate(-50%, 0px);
        -ms-transform: translate(-50%, 0px);
    }
`;

export const bottomFooterGradient = `
    background: linear-gradient(to bottom, #e9e9e9 0%, rgba(233, 233, 233, 0.11) 100%);
    filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#e9e9e9', endColorstr='#1ce9e9e9', GradientType=0);
`;

export const blueGradient = `
    background: #54c8bd;
    background: linear-gradient(to right, #54c8bd 0%, #6364d2 55%, #1840a5 100%);
    filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#54c8bd', endColorstr='#1840a5', GradientType=1);
`;

export const greenGradient = `
    background: #00c6b3;
    background: linear-gradient(to right,#00c6b3 0%,#00c9b1 52%,#0030a3 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00c6b3',endColorstr='#0030a3',GradientType=1);
`;

export const colors = {
    white: '#fff',
    black: '#000',
    black2: '#242527',
    purpleTransparent: 'rgba(41, 41, 94, 0.93)',
    blue: '#134f99',
    lightBlue: '#407cc5',
    lightBlue2: '#bfd3e0',
    darkBlue: '#304770',
    blueTransparent: 'rgba(84, 154, 211, 0.11)',
    orange: '#ff9249',
    orange2: '#ff694a',
    orange3: '#ff0000',
    purple: '#4a4aa2',
    darkPurple: '#6465b5',
    shadowed: 'rgba(0, 0, 0, 0.22)',
    lightShadowed: 'rgba(0, 0, 0, 0.16)',
    superLightShadowed: 'rgba(0, 0, 0, 0.08)',
    green: '#6c9d31',
    lightGreen: '#84ffac',
    grey: '#737a7a',
    lightGrey: '#f2f2f1',
    darkGrey: '#404041',
    
}