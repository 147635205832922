import React, { useState, useContext } from 'react';
import { Link } from 'gatsby';
import { device } from '../../constants/mediaQueries';
import styled from 'styled-components';
import { orangeGradient } from '../../constants/styles';
import { ModalContext } from '../layout';

const Nav = styled.nav`
    @media ${device.deviceM}{
        display: none;
    }
`;

const Toggle = styled.div`
    background: #545454;
    color: #e6e6e6;
    text-align: center;
    display: flex;
`;

const Hamburger = styled.div`
    width: 2rem;
    height: 1rem;
    position: relative;
    margin: 0.5rem auto;
    transform: rotate(0deg);
    transition: .5s ease-in-out;
    cursor: pointer;

    span {
        display: block;
        position: absolute;
        height: 3px;
        width: 100%;
        background: #e6e6e6;
        border-radius: 3px;
        opacity: 1;
        left: 0;
        transform: rotate(0deg);
        transition: .25s ease-in-out;
    }

    span:nth-child(1) {
        top: 0px;
    }

    span:nth-child(2), span:nth-child(3) {
        top: 6px;
    }


    span:nth-child(4) {
        top: 12px
    }

    &.open {
        span:nth-child(1) {
            top: 1px;
            width: 0%;
            left: 50%;
        }

        span:nth-child(2) {
            transform: rotate(45deg);
        }

        span:nth-child(3) {
            transform: rotate(-45deg);
        }

        span:nth-child(4) {
            top: 6px;
            width: 0%;
            left: 50%;
        }
    }
`;


const MenuBar = styled.div`
        height: 6vh;
        display: flex;
        position: relative;
        justify-content: space-between;
        margin: 0 auto;
        z-index: 2;
        align-self: center;
    > div {
        width: 50%;
        height: 100%;
        display: flex;
        float: left;
        border: none;
        border-radius: none;
        font-size: 1.2rem;
        align-items: center;
        justify-content: center;
    }

    .get-in-touch {
        ${orangeGradient}
        color: #ffffff;
    }
`;

const Menu = styled.div`
    background: #545454;
    color: #fff;
    font-size: 1.3rem;
    font-weight: 300;
    line-height: 1.85rem;
    position: absolute;
    display: none;
    transition: .5s ease-in-out;


    ul {
        margin: 0;
        z-index: 10;

        li {
            background: #636363;
            margin-top: 1px;
            margin-bottom: 0;
            padding: 1rem 0.7rem;
        }
    }

    a {
        color: #fff;
        text-decoration: none;
    }

    ul ul {
        a {
            color: #ff9249;
            padding: 0.2rem 1rem;
            line-height: 1.85rem;
            font-size: 1rem;
            display: flex;
        }

        li {
            padding: 0 0.7rem; 
        }
    }

    &.open {
        display: flex;
    }
`;

const MobileNavbar = ({ data }) => {
    
    const modalTools = useContext(ModalContext);
    const [navbarOpen, setNavbarOpen] = useState(false)

    return (
        <Nav>
            <MenuBar>
                <Toggle 
                    navbarOpen={navbarOpen}
                    onClick={() => setNavbarOpen(!navbarOpen)}>
                        <Hamburger className={navbarOpen ? "open" : null}>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                        </Hamburger>
                </Toggle >
                <div className="get-in-touch" onClick={() => modalTools.handleModalCall(true, 'MobileNavbar')}>Get in Touch</div>
            </MenuBar>
            <Menu className={navbarOpen ? "open" : null}>
                <ul>
                    <li>
                        Profit Formula
                        <ul>
                            {data.allContentfulSteps.edges.map(({ node }) => {
                                return (
                                    <Link to={`/steps/${node.link}/`} key={node.title}><span style={{color: "#fff"}}>{node.orderNumber} / </span> {node.title}</Link>
                                );
                            })}
                        </ul>
                    </li>
                    <li>
                        Services
                        <ul>
                            {data.allContentfulServices.edges.map(({ node }) => {
                                return (
                                <Link to={`/services/${node.link}/`} key={node.title}>Stage {node.orderNum}. {node.title}</Link>
                                );
                            })}
                        </ul>
                    </li>
                    <li>
                        <Link to="/cases/">Cases</Link>
                    </li>
                    <li>
                        <Link to="/pricing/">Pricing</Link>
                    </li>
                    <li>
                        Agency
                        <ul>
                            <li>
                                <Link to="/about-us/">About Optimozg</Link>
                            </li>
                            <li>
                                <Link to="/team-optimozg/">Team Optimozg</Link>
                            </li>
                            <li>
                                <Link to="/contact-us/">Contact Us</Link>
                            </li>
                        </ul>
                    </li>
                </ul>
            </Menu>
        </Nav>
    );
}

export default MobileNavbar;