import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import MobileNavbar from './MobileNavbar';
import DesktopNavbar from './DesktopNavbar';

const Navigation = () => {
    const data = useStaticQuery(graphql`
    {
        allContentfulSteps(sort: { order:ASC, fields: orderNumber }) {
          edges {
            node {
              title
              link
              orderNumber
              shortDescription
            }
          }
        }
        allContentfulServices(sort: { order:ASC, fields: orderNum }) {
            edges {
              node {
                title
                orderNum
                link
                shortDescription
              }
            }
        }
    }
    `);

    return (
        <React.Fragment>
            <MobileNavbar data={data} />
            <DesktopNavbar data={data} />
        </React.Fragment>
    );
}

export default Navigation;