import React from 'react';
import styled from 'styled-components';
import { Link, useStaticQuery } from 'gatsby';
import { device } from '../../constants/mediaQueries';
import { colors } from '../../constants/styles';
import SocialIcons from './SocialIcons';
import BottomFooter from './BottomFooter';

const FooterWrap = styled.div`

    font-size: 0.95rem;
    color: ${colors.black};
    padding-top: 4rem;

    .logo-footer {
        a {
            display: block;
        }
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .contact {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .list {
        margin-top: 1rem;

        h3 {
            font-weight: 300;
            color: ${colors.grey};
            font-size: 0.95rem;
            letter-spacing: 0;
            margin-bottom: 0.3rem;
        }

        ul {
            list-style: none;
            padding-left: 0;
            margin-left: 0;

            li {
                margin-bottom: 0.4rem;

                 a {
                     color: ${colors.darkBlue};
                 }
            }
        }

    }

    @media ${device.deviceXS} {
        display: flex;
        flex-flow: row wrap;

        .logo-footer,
        .contact
        {
            width: 100%;
        }
        
        .list {
            width: 50%;
        }
    }

    @media ${device.deviceM} {
        .list,
        .logo-footer,
        .contact {
            width: auto;
            margin-right: 1rem;
            margin-top: 0;

            &:last-child {
                margin-right: 0;
            }
        }
    }
    
`;

const Footer = () => {
    const {logo, steps} = useStaticQuery(
        graphql`
          query {
              logo: allFile(filter: {name: {eq: "logo"}, extension: {eq: "jpg"}}) {
                  edges {
                    node {
                      publicURL
                    }
                  }
              }
              steps: allContentfulSteps(sort: { order:ASC, fields: orderNumber }) {
                edges {
                  node {
                    title
                    link
                    orderNumber
                    shortDescription
                  }
                }
              } 
          }
        `
      );
    return (
        <footer>
            <div className="container">
                <FooterWrap>
                    <div className="logo-footer">
                        <Link to="/"><img src={logo.edges[0].node.publicURL} alt="Optimozg" /></Link>
                        2012-{new Date().getFullYear()} Optimozg LLC.
                    </div>
                    <div className="list">
                        <h3>Step by Step</h3>
                        <ul>
                            {steps.edges.map(({ node }) => {
                                    return (
                                        <li key={node.title}>
                                            <Link to={`/steps/${node.link}`}>
                                                    {node.title} 
                                            </Link>
                                        </li>
                                    );
                            })}
                        </ul>
                    </div>
                    <div className="list">
                        <h3>About Us</h3>
                        <ul>
                            <li>
                                <Link to="/about-us">About Us</Link>
                            </li>
                            <li>
                                <Link to="/team-optimozg">Team Optimozg</Link>
                            </li>
                            <li>
                                <Link to="/contact-us">Contact Us</Link>
                            </li>
                        </ul>
                    </div>
                    <div className="contact">
                        United Kingdom (toll free) +44 800 098 86 50 <br />
                        United States +1 646 661 29 98
                        <SocialIcons />
                    </div>
                </FooterWrap>   
            </div>
            <BottomFooter />
        </footer>
    );
};

export default Footer;