import React, { useState } from "react"
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import PageTransition from 'gatsby-plugin-page-transitions';
import Header from './header';
import Footer from './footer/footer';
import Modal from './reusable/Modal';
import './layout.css';

export const ModalContext = React.createContext('default');

const Layout = ({ children }) => {
  const [displayModal, setDisplayModal] = useState(false);
  const [modalCaller, setModalCaller] = useState('unknown source');
  const handleModalCall = (showModal, clickSource) => {
      setDisplayModal(showModal);
      setModalCaller(clickSource);
  }
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <ModalContext.Provider value={{ handleModalCall, displayModal }}>
      <Header siteTitle={data.site.siteMetadata.title} />
      <PageTransition>
        <main>{children}</main>
      </PageTransition>
      <Footer />
      <Modal clickSource={modalCaller} />
    </ModalContext.Provider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
