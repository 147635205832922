import React from 'react';
import styled from 'styled-components';
import { orangeGradient, colors } from '../../constants/styles';
import { useStaticQuery,  graphql } from 'gatsby';

const Button = styled.button`
    ${orangeGradient}
    color: ${colors.white};
    border: none;
    border-bottom: 2px solid ${colors.orange2};
    font-weight: bold;
    letter-spacing: 0.1em;
    font-size: 0.9rem;
    text-transform: uppercase;
    padding-left: 3.8rem;
    padding-right: 1.7rem;
    padding-top: 0.9rem;
    padding-bottom: 0.9rem;
    position: relative;
    box-shadow: 0px 1.2rem 1.6rem 0px ${colors.shadowed};
    margin-bottom: -1rem;
    margin-top: 1rem;
    cursor: pointer;
    
    &:before {
        content: '';
        position: absolute;
        left: 25px;
        top: 15px;
        width: 31px;
        height: 30px;
        background: url(${props => (props.print)}) center center no-repeat;
        background-size: 30px;
    }
`;

const FormButton = () => {
    const { print } = useStaticQuery(graphql`
        query {
            print: allFile(filter: {name: {eq: "print"}, extension: {eq: "png"}}) {
                edges {
                    node {
                        publicURL
                    }
                }
            }
        }
    `);
    const printURL = print.edges[0].node.publicURL;
    return (
        <Button print={printURL} type="submit">Send</Button>
    );
}

export default FormButton;