import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';

const IconsWrap = styled.div`
    margin-top: 1rem;
    img {
        width: 1.8rem;
        height: 1.8rem;
        margin-right: 3px;
    }
`;

const SocialIcons = () => {
    const icons = useStaticQuery(graphql`
    query {
         facebook: allFile(filter: {name: {eq: "facebook"}, extension: {eq: "png"}}) {
             edges {
                 node {
                     publicURL
                 }
             }
         }
         instagram: allFile(filter: {name: {eq: "instagram"}, extension: {eq: "png"}}) {
             edges {
                 node {
                     publicURL
                 }
             }
         }
         linkedin: allFile(filter: {name: {eq: "linkedin"}, extension: {eq: "png"}}) {
            edges {
                node {
                    publicURL
                }
            }
        } 
     }
 `);
    return (
        <IconsWrap>
            <a href="https://www.facebook.com/optimozg/" target="_blank" rel="noreferrer"><img src={icons.facebook.edges[0].node.publicURL} alt="Facebook Optimozg Link" /></a>
            <a href="https://www.linkedin.com/company/27252484/" target="_blank" rel="noreferrer"><img src={icons.linkedin.edges[0].node.publicURL} alt="LinkedIn Optimozg Link" /></a>
            <a href="https://www.instagram.com/optimozg_agency/" target="_blank" rel="noreferrer"><img src={icons.instagram.edges[0].node.publicURL} alt="Instagram Optimozg Link" /></a>
        </IconsWrap>
    );
}

export default SocialIcons;