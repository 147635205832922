import React from 'react';
import PartnerLogos from '../reusable/PartnerLogos';
import styled from 'styled-components';
import { bottomFooterGradient } from '../../constants/styles';

const BottomFooterWrap = styled.div`
    margin-top: 2.6rem;
    ${bottomFooterGradient}
    padding-bottom: 0.65rem;
`;


const BottomFooter = () => {
    return (
        <BottomFooterWrap>
            <PartnerLogos />
        </BottomFooterWrap>
    );
}

export default BottomFooter;