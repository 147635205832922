import React, { useState } from 'react';
import styled from 'styled-components';
import { colors } from '../../constants/styles';
import FormButton from './FormButton';

const FormWrap = styled.div`

    .inputs {       
        input {
            font-size: 1.2rem;
            border: none;
            padding: 0.7rem 1rem 0.5rem;
            box-sizing: border-box;
            width: 100%;
            border: none;
            color: ${colors.blue};
            font-weight: 300;
            margin-bottom: 1.4rem;
            background: ${colors.white};
        }
    }
`;

const PersonalStrategyForm = ({ clickSource }) => {
    const [url, setUrl] = useState('');
    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const formSource = clickSource || 'unknown source';

    return (
        <FormWrap>
            <form method="POST" name="Personal Strategy Form" data-netlify="true" action="/thank-you" data-netlify-honeypot="bot-field">
                <input type="hidden" name="bot-field" />
                <input type="hidden" name="form-source" value={formSource} />
                <input type="hidden" name="form-name" value="Personal Strategy Form" />
                <div className="inputs">
                    <input
                        type="text"
                        name="name"
                        onChange={(event) => setName(event.target.value)}
                        size="40"
                        value={name}
                        placeholder="Name" />
                    <input 
                        type="text" 
                        name="url" 
                        onChange={(event) => setUrl(event.target.value)}
                        size="40" 
                        value={url}  
                        placeholder="Url" />
                    <input 
                        type="email" 
                        name="email" 
                        onChange={(event) => setEmail(event.target.value)}
                        size="40" 
                        value={email} 
                        placeholder="Email" />
                </div>
                <FormButton />
            </form>
        </FormWrap>
    );
}

export default PersonalStrategyForm;