
export const mediaQueries = {
    mediaXXS: '480px',
    mediaXS: '576px',
    mediaS: '768px',
    mediaM: '992px',
    mediaL: '1200px'
};

export const device = {
    deviceXXS: `(min-width: ${mediaQueries.mediaXXS})`,
    deviceXS: `(min-width: ${mediaQueries.mediaXS})`,
    deviceS: `(min-width: ${mediaQueries.mediaS})`,
    deviceM: `(min-width: ${mediaQueries.mediaM})`,
    deviceL: `(min-width: ${mediaQueries.mediaL})`
  };